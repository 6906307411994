import { useEffectOnce, useSessionStorage } from 'usehooks-ts';
import { env } from '../Environment';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
    position: absolute;
    left: -100px;
    top: 10px;
    bottom: 5px;
    color: black;
    text-align: center;
    background: yellow;
    width: 90px;
    padding-top: 28px;
    font-size: 1.4em;
    border-radius: 50px;
    border: 1px solid #eee;
`;

export const EnvironmentBatch = () => {
    const [visibility, setVisibility] = useSessionStorage<'hide' | 'show'>('environment-batch-visibility',  'show');
    useEffectOnce(() => {
        if (window.location.search.indexOf('hide-env-batch') >= 0) {
            setVisibility('hide');
        }
    });
    
    if (env.name === 'production') return null;
    if (visibility === 'hide') return null;
    
    const envName = env.name.charAt(0).toUpperCase() + env.name.substring(1);
    return (
        <StyledDiv>{envName}!</StyledDiv>
    )
}