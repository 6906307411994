import styled from '@emotion/styled';
import { useContext, useEffect } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { ContentNotFoundPage } from "../pages/errors/ContentNotFoundPage";
import { HomePage } from "../pages/home/HomePage";
import { OneClickmpytingSchedulingPage, OneTimeEmpytingSchedulingPage, SeriesEmpytingSchedulingPage } from "../pages/emptying-scheduling/EmptyingSchedulingPage";
import { CreditOverviewPage } from "../pages/transaction-history/CreditOverviewPage";
import { ErrorBoundary } from "./ErrorBoundary";
import { InvitationRedemptionPage } from "../pages/invitation-redemption/InvitationRedemptionPage";
import { PageHeader } from "./PageHeader";
import { LeftNavigation } from "./LeftNavigation";
import { PageFooter } from "./PageFooter";
import { SplashScreen } from "./SplashScreen";
import { UnauthenticatedPage } from '../pages/errors/UnauthenticatedPage';
import { PageHeaderMobile } from './PageHeaderMobile';
import { ROUTES } from './Routes';
import { OrganisationOverviewPage } from '../pages/organisation/OrganisationOverviewPage';
import { ContainerOverviewPage } from '../pages/container-overview/ContainerOverviewPage';
import { UserProfilePage } from '../pages/profile/UserProfilePage';
import { AccountProfilePage } from '../pages/profile/AccountProfilePage';
import { ManualsPage } from '../pages/infos/ManualsPage';
import { FaqPage } from '../pages/infos/FaqPage';
import { ContactPage } from '../pages/infos/ContactPage';
import { ImprintInfosPage } from '../pages/infos/ImprintInfosPage';
import { EmptyingConfirmationOverviewPage } from '../pages/transaction-history/EmptyingConfirmationsPage';
import { PaymentsOverviewPage } from '../pages/transaction-history/PaymentsPage';
import { TermsAndConditionsPage } from '../pages/infos/TermsAndConditionsPage';

const PageWithoutSkeleton = ({ children }: { children: any }) => {
    return <Page showHeader={false} showFooter={false} showNavigation={false}>{children}</Page>
}
const PageWithoutNav = ({ children }: { children: any }) => {
    return <Page showHeader={true} showFooter={true} showNavigation={false}>{children}</Page>
}
const FullPage = ({ children }: { children: any }) => {
    return <Page showHeader={true} showFooter={true} showNavigation={true}>{children}</Page>
}

const StyledPage = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 962px) {
        flex-direction: column;
    }
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-width: 1100px;
    margin: 0 auto 0 344px;
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden;

    @media (max-width: 1919px) {
        margin: 0 auto 0 320px;
    }

    @media (max-width: 1640px) {
        margin: 0 auto 0 296px;
    }

    @media (max-width: 1279px) {
        margin: 0 auto 0 272px;
    }
    
    @media (max-width: 962px) {
        margin: 0;
    }
`;

const PageContent = styled.div`
    flex: 1;
    margin-top: 124px;

    @media (max-width: 962px) {
        margin-top: 114px;
    }
`;

// TODO: why are there two PageHeader components - one for mobile, one for desktop. They get rendered both, even though one of them is hidden...

const Page = ({ children, showHeader, showFooter, showNavigation }: { showHeader: boolean, showFooter: boolean, showNavigation: boolean, children: any }) => {
    
    const { pathname } = useLocation();
  
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <StyledPage>
            {showNavigation && <PageHeaderMobile />}
            {showNavigation && <LeftNavigation />}
            <MainWrapper className="main-wrapper">
                {showHeader && <PageHeader />}
                <PageContent>
                    {children}
                </PageContent>
                {showFooter && (
                    <div>
                        <PageFooter />
                    </div>
                )}
            </MainWrapper>
        </StyledPage>
    );
}


export const PageSkeleton = () => {
    const userContext = useContext(UserContext);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path={ROUTES.Unauthenticated} element={<PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>} />
                <Route path={ROUTES.Home} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><HomePage /></FullPage>}
                </>} />
                <Route path={ROUTES.InvitationRedemption} element={<>
                    {!userContext.loggedInUser && <PageWithoutSkeleton><InvitationRedemptionPage /></PageWithoutSkeleton>}
                    {userContext.loggedInUser && <PageWithoutNav><InvitationRedemptionPage /></PageWithoutNav>}
                </>} />
                <Route path={ROUTES.SingleEmptying} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <OneTimeEmpytingSchedulingPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.OneClickEmptying} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <OneClickmpytingSchedulingPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.MultiEmptying} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <SeriesEmpytingSchedulingPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.CreditOverview} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <CreditOverviewPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.EmptyingConfirmations} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <EmptyingConfirmationOverviewPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.Payments} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.payment ? <PaymentsOverviewPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.ContainerOverview} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.container ? <ContainerOverviewPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.Organisation} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser &&
                        <FullPage>
                            {userContext.accessPermission?.roles?.permission ? <OrganisationOverviewPage /> : <UnauthenticatedPage />}
                        </FullPage>
                    }
                </>} />
                <Route path={ROUTES.UserProfile} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><UserProfilePage /></FullPage>}
                </>} />
                <Route path={ROUTES.AccountProfile} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><AccountProfilePage /></FullPage>}
                </>} />
                <Route path={ROUTES.Manuals} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><ManualsPage /></FullPage>}
                </>} />
                <Route path={ROUTES.Faq} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><FaqPage /></FullPage>}
                </>} />
                <Route path={ROUTES.Contact} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><UnauthenticatedPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><ContactPage /></FullPage>}
                </>} />
                <Route path={ROUTES.ImprintInfos} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><ImprintInfosPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><ImprintInfosPage /></FullPage>}
                </>} />
                <Route path={ROUTES.TermsAndConditions} element={<>
                    {!userContext.loggedInUser && <PageWithoutNav><TermsAndConditionsPage /></PageWithoutNav>}
                    {userContext.loggedInUser && <FullPage><TermsAndConditionsPage /></FullPage>}
                </>} />
                <Route path={ROUTES.Logout} element={
                    <PageWithoutNav><UnauthenticatedPage mode="loggedOut" /></PageWithoutNav>
                } />
                <Route path="*" element={<FullPage><ContentNotFoundPage /></FullPage>} />
            </>
        )
    );

    return (
        <ErrorBoundary>
            {userContext.loading &&
                <SplashScreen />
            }
            {!userContext.loading &&
                <RouterProvider router={router} />
            }
        </ErrorBoundary>
    )
}