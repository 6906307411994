import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from '@emotion/styled';
import Button from '../Button';
import { PaymentMethodChooser } from "./PaymentMethodChooser";
import { PaymentManager } from "./usePaymentManager";
import { PaymentMethod } from "../../types/PaymentMethod";
import { IconClose } from "../Icons";
import { styles } from "../../styles";
import { MessageWrapper } from "../MessageWrapper";
import { PaymentTransactionProcess } from "../../types/PaymentTransaction";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../skeleton/Routes";
import { Loader } from "../Loader";

type PaymentStepProps = {
    paymentMgr: PaymentManager;
    payWithCreditBtnLabel?: string;
    process: PaymentTransactionProcess;
    orderItemName: string;
    totalPrice?: number;
    enableCreditUsage: boolean;
    enableInvoice: boolean;
    onCancel: () => void;
    texts: {
        title: string;
        priceFieldLabel?: string;
        paymentMethodFieldLabel?: string;
    }
}

const PaymentStepWrapper = styled.div`
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
`;

const ContentWrapper = styled.div`
    overflow-y: auto;
    position: relative;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

const Header = styled.h2`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    border-radius: 8px;
    margin-bottom: 0;
    width: 100%;

    svg {
        cursor: pointer;
    }
`;

const StyledH3 = styled.h3`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
`;
const TopMargin = styled.div`
    margin-top: 24px;
`;

type StyledInputProps = {
    hasError?: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    padding: 10px 16px;
    flex: 1;
    box-shadow: ${styles.boxShadowInset};
    background-color: #fff;
    border: 1px solid ${props => props.hasError ? '#ed2b2b' : styles.neutral200};
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 14px;
    color: ${styles.textColorSecondary700};
    font-family: 'Satoshi Regular';
    max-height: 44px;

    &:focus {
        border: 1px solid #3371EA;
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
    }
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 48px;

    button {
        width: 156px;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        width: 100%;

        button {
            width: 100%;
            margin-bottom: 0;
        }
    }
`;

// RESOLVED: Why do we need the button 'Weiter'? When choosing a payment method, the process can already go on...
// According to design file user should be able to select first and then decide to go further with it (the selection is implemented)

const MAX_AMOUNT_IN_CHF = 10000;

export const PaymentStep: React.FC<PaymentStepProps> = ({ paymentMgr, payWithCreditBtnLabel, enableInvoice, process, orderItemName, totalPrice, enableCreditUsage, onCancel, texts }) => {
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>();
    const [selectedPrice, setSelectedPrice] = useState<number>(totalPrice || 0);
    const [state, setState] = useState<undefined | 'running-transaction' | 'finished'>();
    const { t } = useTranslation();

    const onStartPaymentTransaction = async () => {
        if (selectedMethod && selectedPrice > 0) {
            setState('running-transaction');
            const savedCursorStyle = window.document.body.style.cursor;
            window.document.body.style.cursor = 'wait';
            await paymentMgr.initPaymentTransactionMutation.mutateAsync({
                amountInChf: Math.min(MAX_AMOUNT_IN_CHF, selectedPrice),
                initiatingProcess: process,
                transactionName: orderItemName,
                paymentMethod: selectedMethod
            });
            if (selectedMethod === 'Guthaben' || selectedMethod === 'Rechnung') {
                setState('finished');
                window.document.body.style.cursor = savedCursorStyle;
            }
        }
    }

    const onCancelClicked = () => {
        if (state !== 'running-transaction') {
            onCancel();
        }
    }

    const isError = paymentMgr.initPaymentTransactionMutation.isError && paymentMgr.initPaymentTransactionMutation.error;

    if (!isError && state === 'finished' && selectedMethod === 'Rechnung') {
        return (
            <PaymentStepWrapper>
                <Header>{t('pages.home.creditSection.topUpModal.payByInvoiceMsg.title')} <div onClick={onCancelClicked}><IconClose /></div></Header>
                <ContentWrapper>
                    <div><b>{t('pages.home.creditSection.topUpModal.payByInvoiceMsg.text1')}</b></div>
                    <div>{t('pages.home.creditSection.topUpModal.payByInvoiceMsg.text2')}</div>
                    <div style={{fontSize: '0.85em'}}>{t('pages.home.creditSection.topUpModal.payByInvoiceMsg.text3')}</div>
                    <BottomRow>
                        <Button buttonVariant="ghost" onClick={onCancelClicked}>
                            {t('pages.home.creditSection.topUpModal.payByInvoiceMsg.secondaryBtn')}
                        </Button>
                        <NavLink to={ROUTES.Payments} >
                            <Button buttonVariant="primary">
                                {t('pages.home.creditSection.topUpModal.payByInvoiceMsg.primaryBtn')}
                            </Button>
                        </NavLink>
                    </BottomRow>
                </ContentWrapper>
            </PaymentStepWrapper>
        )
    }

    return (
        <PaymentStepWrapper>
            <Header>{texts.title} <div onClick={onCancelClicked}><IconClose /></div></Header>
            <ContentWrapper>
                {
                    isError && <MessageWrapper messageText={paymentMgr.initPaymentTransactionMutation.error?.message} height={"496px"} />
                }
                {
                    !isError && (
                        <>
                            <div>
                                {texts.priceFieldLabel &&
                                    <>
                                        <TopMargin />
                                        <StyledH3>{t("pages.home.creditSection.topUpModal.topUpAmount")}</StyledH3>
                                    </>
                                }
                                {(!totalPrice || totalPrice <= 0) &&
                                    <><StyledInput
                                        type="text"
                                        placeholder="CHF"
                                        disabled={!!state}
                                        value={selectedPrice <= 0 ? "" : Math.min(selectedPrice, MAX_AMOUNT_IN_CHF)}
                                        onChange={evt => { setSelectedPrice(parseInt(evt.target.value) || 0) }}
                                    />
                                        {selectedPrice > MAX_AMOUNT_IN_CHF &&
                                            <MessageWrapper
                                                messageText={
                                                    <Trans
                                                        i18nKey="pages.home.creditSection.topUpModal.maxAmountWarning"
                                                        components={{ 1: <span /> }}
                                                        values={{ textValue: MAX_AMOUNT_IN_CHF }}
                                                    />
                                                }
                                                justifyContent={"flex-start"}
                                                svgMargin={"0 8px 0 0"}
                                                fontSize={"10px"}
                                                margin={"12px 0 0 0"}
                                            />
                                        }
                                    </>
                                }
                                {texts.paymentMethodFieldLabel &&
                                    <StyledH3 style={{ marginTop: "24px", marginBottom: "-12px" }}>{t("pages.home.creditSection.topUpModal.meansOfPayment")}</StyledH3>
                                }
                                <div>
                                    <PaymentMethodChooser
                                        creditEnabled={enableCreditUsage}
                                        invoiceEnabled={enableInvoice}
                                        disabled={!!state}
                                        onUpdate={setSelectedMethod}
                                        hideCredit={!totalPrice || totalPrice <= 0}
                                    />
                                </div>
                            </div>
                            <BottomRow>
                                <Button buttonVariant="ghost" onClick={onCancelClicked}>
                                    {t("buttons.abort")}
                                </Button>
                                <Button disabled={selectedPrice <= 0 || !selectedMethod || !!state} onClick={onStartPaymentTransaction}>
                                    {!!state && <Loader />}
                                    {!state && <>{selectedMethod === 'Guthaben' && payWithCreditBtnLabel ? payWithCreditBtnLabel : t("buttons.next")}</>}
                                </Button>
                            </BottomRow>
                        </>
                    )
                }
            </ContentWrapper>
        </PaymentStepWrapper>
    );
};
